import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js'
import { v4 as uuidv4 } from 'uuid';
import { startOfMonth, startOfDay, subMonths, subDays } from 'date-fns';
import { Switch, Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import Medal from './images/medal.png'
import Medal_1 from './images/medal_1.png'
import Medal_2 from './images/medal_2.png'
function App() {

  const supabaseUrl = "https://nmbgtmovabrjbqxqiljx.supabase.co" //Deno.env.get("SUPABASE_URL");
  const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5tYmd0bW92YWJyamJxeHFpbGp4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc5NjU0NDUsImV4cCI6MjA0MzU0MTQ0NX0.7xdZs5rDpZ6iNI7JWaO5GmzoiY315Nl41llaPsQ_fOo'

  const supabase = createClient(supabaseUrl, supabaseKey)
  const [revenue, setRevenue] = useState([]);
  const [prevRevenue, setPrevRevenue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [merchants, setMerchants] = useState([]);
  const [updatedMerchantId, setUpdatedMerchantId] = useState(null);
  const [timeRange, setTimeRange] = useState('daily');
  const [merchantRevenues, setMerchantRevenues] = useState([]);
  const [open, setOpen] = useState(false)
  const [allTotalRevenue, setAllTotalRevenue] = useState(0)
  const [prevAllTotalRevenue, setPrevAllTotalRevenue] = useState(0)
  const [allTotalRevenueChange, setAllTotalRevenueChange] = useState(0)
  const [selectedSalesChannels, setSelectedSalesChannels] = useState(["Mobil App", "Web", "Hepsiburada", "Trendyol", "Toplam"])
  const color = ["text-[#FED862]", "text-[#C0CCCE]", "text-[#877551]"];

  const getRevenues = async () => {

    //if timeRange is monthly eq in this month
    //if timeRange is daily eq in this day

    const startDate = timeRange === "monthly" ? startOfMonth(new Date()) : startOfDay(new Date());
    const previousDate = timeRange === "monthly" ? startOfMonth(subMonths(new Date(), 1)) : startOfDay(subDays(new Date(), 1));

    let { data: revenues, error1 } = await supabase
      .from('dijipo_ciro')
      .select('*')
      .gte('created_at', startDate.toISOString())

    let { data: prevRevenue, error2 } = await supabase
      .from('dijipo_ciro')
      .select('*')
      .gte('created_at', previousDate.toISOString())
      .lte('created_at', startDate.toISOString())

    if (error1) console.error('Error fetching data:', error1);
    else {
      setRevenue(revenues);
      setPrevRevenue(prevRevenue);
    }
    setLoading(false);
  }

  const getMerchants = async () => {
    let { data: merchants, error } = await supabase
      .from('merchants')
      .select('*');
    if (error) console.error('Error fetching data:', error);
    else setMerchants(merchants.map((merchant) => (
      {
        ...merchant,
        revenues: [],
      }
    )))
  }

  useEffect(() => {

    let allTotalRevenueCounter = 0;
    let prevTotalRevenueCounter = 0;
    if (revenue?.length > 0) {
      const merchantRevenues = merchants.map((merchant) => {
        return {
          ...merchant,
          revenues: revenue.filter((revenue_data) => revenue_data.merchant_id === merchant.merchant_id),
          prevRevenues: prevRevenue.filter((revenue_data) => revenue_data.merchant_id === merchant.merchant_id)
        }
      });

      merchantRevenues.forEach((merchant) => {

        merchant.total_revenue = {
          total_revenue: merchant.revenues.reduce((acc, revenue) => {
            return acc + revenue.price;

          }, 0),
          count: merchant.revenues.length,
        };
        merchant.prev_total_revenue = {
          total_revenue: merchant.prevRevenues.reduce((acc, revenue) => {
            return acc + revenue.price;

          }, 0),
          count: merchant.prevRevenues.length,
        };

        allTotalRevenueCounter += merchant.total_revenue.total_revenue;
        prevTotalRevenueCounter += merchant.prev_total_revenue.total_revenue;

        merchant.changes = (((merchant.total_revenue.total_revenue - merchant.prev_total_revenue.total_revenue) / merchant.prev_total_revenue.total_revenue) * 100);
        if (!isFinite(merchant.changes)) {
          merchant.changes = undefined
        }
      });

      setAllTotalRevenue(allTotalRevenueCounter);
      setPrevAllTotalRevenue(prevTotalRevenueCounter);
      setAllTotalRevenueChange(((allTotalRevenueCounter - prevTotalRevenueCounter) / prevTotalRevenueCounter) * 100)
      setMerchantRevenues(merchantRevenues);
    }
  }, [revenue]);

  const updateRevenue = (newRevenue) => {
    setRevenue((prevRevenue) => {
      return [...prevRevenue, newRevenue
      ]
    })
  }

  useEffect(() => {
    getMerchants()
    getRevenues()

    const subscription = supabase.channel('custom-all-channel').on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'dijipo_ciro' },
      (payload) => {
        updateRevenue(payload.new)
        setUpdatedMerchantId(payload.new.merchant_id)
      }).subscribe()

  }, []);


  useEffect(() => {
    //after 1 sec
    setTimeout(() => {
      setUpdatedMerchantId(null)
    }, 1000)

  }, [updatedMerchantId])

  useEffect(() => {
    getRevenues();
  }, [timeRange])


  if (loading) return <div className="text-center">Loading...</div>;

  return (
    <>
      <div className=" mx-auto p-6 bg-gray-100 " key="channel.name">
        <div className='flex flex-row items-center justify-end gap-2 mb-3'>
          <span className="text-gray-500 mx-2 font-semibold text-lg">Toplam Ciro:
            <span className='text-gray-900 text-xl ml-2'>
              {(allTotalRevenue || 0).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })} ₺
            </span>
            <span className={`rounded-xl text-xs ${allTotalRevenueChange < 0 ? "text-red-500" : "text-green-500"}`}>{(allTotalRevenueChange) > 0 && "+"}{(allTotalRevenueChange)?.toFixed(0)}%</span>
          </span>
          <span onClick={() => { setTimeRange("monthly") }}>Aylık</span>
          <Switch
            checked={timeRange == 'daily'}
            onChange={() => { setTimeRange(timeRange == 'monthly' ? 'daily' : 'monthly') }}
            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent  transition-colors duration-200 ease-in-out data-[checked]:bg-orange-400 bg-green-600"
          >
            <span className="sr-only"></span>
            <span
              aria-hidden="true"
              className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
            />
          </Switch>
          <span onClick={() => { setTimeRange("daily") }}>Günlük</span>
        </div>
        <>
          {merchants && (
            <div className="grid grid-cols-1 gap-6 grid-cols-1 lg:grid-cols-3 xl:grid-cols-4">
              {merchantRevenues.sort((a, b) => {
                const revenueA = a.total_revenue?.total_revenue || 0;
                const revenueB = b.total_revenue?.total_revenue || 0;
                return revenueB - revenueA;
              }).map((merchant, i) => (
                <div key={merchant.merchant_id} className={`${updatedMerchantId == merchant.merchant_id ? "animate-neon" : ""} ${merchant?.changes > 0 ? "bg-green-300" : "bg-red-300"} overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 transform transition duration-300 hover:scale-110 cursor-pointer`}>
                  <div className={`flex flex-row truncate text-xl font-bold text-gray-800`}>
                    <span >
                      {i == 0 && <img src={Medal} className='w-[30px] h-[30px]' />}
                      {i == 1 && <img src={Medal_1} className='w-[30px] h-[30px]' />}
                      {i == 2 && <img src={Medal_2} className='w-[30px] h-[30px]' />}</span>
                    {merchant.store_name}</div>
                  <span className="truncate text-[10px] font-medium text-gray-500">{merchant.subdomain}.myikas.com</span>
                  <div className='flex flex-row justify-between items-center '>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                      {(merchant.total_revenue?.total_revenue || 0).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })} ₺
                      {merchant.changes && <span className={`rounded-xl text-xs ${merchant?.changes < 0 ? "text-red-700" : "text-green-700"}`}>{(merchant?.changes) > 0 && "+"}{(merchant?.changes)?.toFixed(0)}%</span>}
                    </dd>

                    <dd className="mt-1 text-xl text-gray-500 font-semibold tracking-tight ">
                      {(merchant.revenues?.length || 0)} sipariş
                    </dd>
                  </div>
                </div>
              ))
              }

            </div>
          )}

        </>
      </div>
    </>
  );
}

export default App;


